import React from "react";
import "../styles/AboutUsPage.css";

const AboutUsPage = () => {
  return (
    <div className="aup--container">
      <div className="aup--list__container">
        <h1 className="aup--list__title">Чем мы занимаемся</h1>
        <ul className="aup--list">
          {[
            "содействуем разработке программ развития и расширения производственной базы, расположенных в городе Москве спортсооружений и спортивных объектов",
            "содействуем совершенствованию системы подготовки высококвалифицированных спортсменов",
            "осуществляем координацию и участие в организации и проведении спортивных соревнований различного ранга, в том числе международных",
            "осуществляем направление спортсменов, тренеров, судей на международные, российские, региональные соревнования и другие спортивные мероприятия",
            "участвуем в организации и проведении учебно-тренировочного процесса и учебно-тренировочных сборов, в том числе с выездом",
            "оказываем помощь в подборе и расстановке кадров, тренеров и специалистов",
            "содействуем разработке и осуществлению планов подготовки и переподготовки специалистов, оказывает помощь в организации и проведении семинаров тренерского и судейского состава",
            "участвуем в организации и обеспечении проведения научных исследований по вопросам развития настольного тенниса, создании банка данных по новейшим достижениям в организации тренировочного процесса и применяемого спортивного инвентаря",
            "другие направления, содействующие развитию и укреплению массового спортивного движения",
          ].map((item, index) => (
            <li key={index} className="aup--list__line">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="aup--presidium__container">
        <h1 className="aup--presidium__title">Президиум</h1>
        <div className="aup--card__container">
          {[
            {
              name: "Пилип Марк Михайлович​",
              jobTitle: "Президент федерации",
              img: "pilip_mark.jpg",
            },
            {
              name: "Шерман Александр Яковлевич​",
              jobTitle:
                "Член президиума, Руководитель мужской вертикали сборной команды г. Москвы",
              img: "sherman_alexander.jpg",
            },
            {
              name: "Муравьева Марина Анатольевна​",
              jobTitle:
                "Член президиума, Руководитель женской вертикали сборной команды г. Москвы",
              img: "muravieva_marina.jpg",
            },
            {
              name: "Ступаченко Лев Николаевич​",
              jobTitle: "Член президиума",
              img: "stupachenko_lev.jpg",
            },
            {
              name: "Спиридонов Владимир Васильевич​",
              jobTitle: "Член президиума, Председатель тренерского совета",
              img: "spiridonov_vladimir.jpg",
            },
            {
              name: "Рубцов Игорь Александрович​",
              jobTitle:
                "Член президиума, Ст. тренер мужской команды сборной г. Москвы",
              img: "rubcov_igor.gif",
            },
            {
              name: "Крутов Андрей Анатольевич​",
              jobTitle:
                "Член президиума, Ст. тренер женской команды сборной г. Москвы",
              img: "krutov_andrey.jpg",
            },
            {
              name: "Шахова Татьяна Владимировна​",
              jobTitle: "Член президиума",
              img: "shahova_tatiana.jpg",
            },
            {
              name: "Болотина Марина Евгеньевна​",
              jobTitle: "Член президиума",
              img: "",
            },
          ].map((person, index) => (
            <div key={index} className="aup--card">
              <img
                className="aup--card__img"
                alt={person.name}
                src={`media/${person.img}`}
              />
              <div className="aup--card__img" />
              <h1 className="aup--card__full-name">{person.name}</h1>
              <h2 className="aup--card__job-title">{person.jobTitle}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
