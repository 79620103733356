import React from "react";
import "../styles/ContactsPage.css";

const socialLinks = {
  facebook: "https://www.facebook.com/moscowftt/",
  vkontakte: "https://vk.com/moscowftt",
  telegram: "https://telegram.me/moscowftt",
  youtube: "https://www.youtube.com/MoscowTableTennisFederation",
  instagram: "http://www.instagram.com/tt_moscow/",
};

const handleClick = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const ContactsPage = () => (
  <div className="cnp--container">
    <h1 className="cnp--title">Контакты</h1>
    <p className="cnp--title__text">Куда и к кому обращаться</p>
    <div className="cnp--contacts__container">
      <p className="cnp--email">email: mfntmail@gmail.com</p>
      <div className="cnp--card__container">
        {Object.keys(socialLinks).map((socialMedia, index) => (
          <div
            key={index}
            className={`cnp--card__${socialMedia}`}
            onClick={() => handleClick(socialLinks[socialMedia])}
            style={{ cursor: "pointer" }}
          >
            <p className="cnp--card__text">{socialMedia}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default ContactsPage;
