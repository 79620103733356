import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import "../styles/Header.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navLinks = [
    { to: "/charts", text: "рейтинг" },
    { to: "/calendar", text: "календарь" },
    { to: "/where-to-play", text: "площадки" },
    { to: "/about-us", text: "о нас" },
    { to: "/documents", text: "документы" },
    { to: "/contacts", text: "контакты" },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className="header--container">
      <header className="header">
        <Link className="header--link" to="/">
          <img
            src="/media/мфнт-лого.png"
            alt="логотип мфнт"
            className="header--icon"
          />
        </Link>

        <div className="header--divider" />

        <button className="header--button" onClick={toggleMenu}>
          <FaBars className="header--button__icon" />
        </button>

        <div className={`header--nav__links ${isOpen ? "open" : ""}`}>
          {navLinks.map((link, index) => (
            <Link key={index} className="header--link" to={link.to} onClick={closeMenu}>
              <h1 className="header--text">{link.text}</h1>
            </Link>
          ))}
        </div>
      </header>
    </div>
  );
};

export default Header;
